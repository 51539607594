<template>
  <div class="nvx-app-container">
    <div class="delete-pop" v-if="showDelete">
      <DeleteLinkPanel 
        :show-delete="showDelete" 
        @close-delete="closeDelete" 
        @call-delete="deleteLink(linkToDelete)">
      </DeleteLinkPanel>
    </div>
    <div class="alert-pop" v-if="alertPop">
      <div class="copied-pop" v-if="isCopy">
        <span data-feather="check-circle"></span>
        {{ $t('link_copied') }}
      </div>
      <div class="alert" v-else>
        {{ $t('link_create_max') }}
      </div>
    </div>
    <div class="pseudo-nav-bar">
      <div v-if="lastRoute">
        <router-link :to="'/app/theft_file?serial=' + device.serial">
          <icon-wrapper :iconType="'chevron-left'" />
        </router-link>
      </div>
      <div class="nav-container">
        <h2>{{ $t('sharing_location') }}</h2>
        <span @click="isEditing = !isEditing" class="text-button">
          {{ isEditing ? $t('ok') : $t('edit')  }}
        </span>
      </div>
    </div>
    <p>{{ $t('link_public_desc') }}</p>
    <div class="add-panel" v-show="showPanel">
      <h3>{{ $t('create_link') }}</h3>
      <div class="link-options">
        <div>
          <div class="label-container">
            <span class="select-label">{{ $t('link_date_start') }}</span>
          </div>
          <Multiselect
          class="select-link"
          v-model="newPubLink.start_date"
          :options="startOptions"
          :multiple="false"
          :close-on-select="true"
          :hide-selected="true"
          :preserve-search="true"
          :custom-label="startDateLabel"
          :select-label="''"
          :searchable="false"
          >
          </Multiselect>
        </div>
      
        <div>
          <div class="label-container">
            <span class="select-label">{{ $t('link_date_end') }}</span>
          </div>
          <Multiselect
          class="select-link"
          v-model="newPubLink.duration"
          :value="12"
          :options="durationOptions"
          :multiple="false"
          :close-on-select="true"
          :hide-selected="true"
          :preserve-search="true"
          :custom-label="durationLabel"
          :select-label="''"
          :searchable="false"
          >
          </Multiselect>
        </div>

        <div class="label-container">
          <span class="select-label">{{ $t('enter_label') }} ({{ $t('optional') }})</span>
          <input 
            type="text" 
            name="label" 
            id="label" 
            placeholder="label" 
            v-model="newPubLink.label" 
            class="link-label"
          >
        </div>
        <div class="btn-panel">
          <button class="add-btn cancel" @click="showPanel = false">{{ $t('cancel') }}</button>
          <button class="add-btn" @click="createLink(); showPanel = false">{{ $t('create') }}</button>
        </div>
      </div>
    </div>
    <div class="nvx-vlist" v-show="!showPanel && links">
      <nvx-section :header="'public_link_header'">
        <div class="nvx-cellule"  v-for="item in otherLinks" :key="item.uuid">
          <button v-if="isEditing" @click="showDelete = !showDelete; linkToDelete = item; scroll()" @close-popup="closeDelete" class="cell-disclosure">
            <icon-wrapper :iconType="'icon-delete-cell'"/>
          </button>
          <div class="cell-container">
            <div v-if="item.label">{{ $t('link_label_fmt', {label: item.label}) }}</div>
            <div v-else>{{ $t('link_label_fmt', {label: ""}) }}</div>
            <div class="cell-detail">{{ [item.start_date, item.expiration_date] | validityInterval }}</div>
          </div>
          <div class="button-container">
            <button v-if="!isEditing" @click="shareUrl(item)" class="cell-disclosure">
              <icon-wrapper :iconType="'icon-share'"/>
            </button>
          </div>
        </div>
        <nvx-button-cell v-if="otherLinks.length < 5" :target="onAddLink">
          {{ $t('create_link') }}
        </nvx-button-cell>
      </nvx-section>
      <nvx-section v-if="theftLinks.length > 0" :header="'theft_link_header'">
        <div class="nvx-cellule"  v-for="item in theftLinks" :key="item.uuid">
          <div class="cell-container">
            <div>{{ $t('link_label_fmt', {label: $t('theft_report_tag')}) }}</div>
            <div class="cell-detail">{{ [item.start_date, item.expiration_date] | validityInterval }}</div>
          </div>
          <div class="button-container">
            <button @click="shareUrl(item)" class="cell-disclosure">
              <icon-wrapper :iconType="'icon-share'"/>
            </button>
          </div>
        </div>
      </nvx-section>

    </div>
  </div>
</template>

<script>
import { getAllPulicLinks, addPublicLink, deletePublicLink } from '../api/devices.api';
import { mapActions, mapGetters } from "vuex";
import moment from 'moment';

import Multiselect from 'vue-multiselect'
import DeleteLinkPanel from '../components/links/DeleteLinkPanel.vue';
import {getAppOs} from '@/api/appcompat.api'
import IconWrapper from "@/components/ui/IconWrapper.vue";
import NvxSection from "@/components/ui/NvxSection.vue";
import NvxButtonCell from "@/components/ui/NvxCell.vue";

const feather = require('feather-icons')

export default {
  name: 'PublicLinks',
  components: {
    Multiselect,
    DeleteLinkPanel,
    IconWrapper,
    NvxSection,
    NvxButtonCell
  },
  data() {
    return {
      links: [],
      device: null,
      userOS: null,
      newPubLink: {type: 'history', duration: 168, start_date: this.lastEvent},
      durationOptions: [24, 168, 730, -1],
      startOptions: [],
      isCopy: false,
      isEditing: false,
      showPanel: false,
      showDelete: false,
      linkToDelete: null,
      lastEvent: null,
      month: moment().subtract(1, 'months').format(),
      week: moment().subtract(1, 'week').format(),
      day: moment().subtract(1, 'day').format(),
      alertPop: false,
      loaded: false
    };
  },
  computed: {
    ...mapGetters("devices", ["getDevices", "getSelectedDevice"]),
    lastRoute() {
      if (localStorage.getItem('lastVisitedRoute') && localStorage.getItem('lastVisitedRoute') == 'theftFile') {
        return true
      }
      return false
    },
    otherLinks() {
      return this.links.filter((link) => !this.theftLink(link) );
    },
    theftLinks() {
      return this.links.filter((link) => this.theftLink(link) );
    }

  },
  methods: {
    ...mapActions('devices', ['fetchDevices', 'setSelectedDevice']),
    ...mapActions('devices', ['removeDevices']),
    ...mapActions('devices', ['removeSelectedDevice']),
    ...mapActions("users", ["setToken"]),
    isLinks() {
      this.links.length
      if (this.links.length == 0) {
        return false
      }
      return true
    },
    closeDelete() {
      this.showDelete = false; // Close the popup when the close event is emitted
    },
    scroll() {
      window.scrollTo(0,0)
    },

    onAddLink() {
      // FIXME: after link creation value are not resetted, 
      // so the second link creation is weird.
      this.showPanel = true;
    },

    durationLabel(option) {
      // This would be better to have the same unit for positive and
      // negative time difference format. 
      // And +1 month is not +30 days; it is d.setMonth( d.getMonth() + 1)
      // And it would be event better to convert end date to end of day for this date
      // Below are hours.

      const locale = navigator.language
      const rtf = new Intl.RelativeTimeFormat(locale, {
        localeMatcher: 'best fit',
        numeric: 'always',
        style: 'long',
      });

      if (option == -1) {
        return this.$i18n.t('never_ending')
      } else if (option == 24) {
        return rtf.format(1, "day");
      } else if (option == 168) {
        return rtf.format(1, "week");
      } else if (option == 730) {
        return rtf.format(1, "month");
      } else {
        return rtf.format(option, "hour");
      }
    },
    startDateLabel(option) {
      // This would be better to have the same unit for positive and
      // negative time difference format. 
      // And -1 month is not -30 days; it is d.setMonth( d.getMonth() + 1)

      const locale = navigator.language
      const rtf = new Intl.RelativeTimeFormat(locale, {
        localeMatcher: 'best fit',
        numeric: 'always',
        style: 'long',
      });

      if (option == this.device.tracker_status.last_event) {
        return this.$i18n.t('last_location')
      } else if (option == this.day) {
        return rtf.format(-1, "day");
      } else if (option == this.week) {
        return rtf.format(-1, "week");
      } else if (option == this.month) {
        return rtf.format(-1, "month")
      } else if (option == this.device.tracker_status.begin_date) {
        return this.$i18n.t('link_from_beginning')
      } else {
        return option
      }
    },

    theftLink(item) {
      if (item.category != null) {
        return (item.category == 'theft')
      }
      return false
    },

    async shareUrl(item) {
      console.log('Sharing...');

      const title = this.$i18n.t('link_share_title');
      const text = this.$i18n.t('link_share_message');

      if (this.userOS == 'Android') {
        // eslint-disable-next-line
        if (NvxAndroid.shareDataUrl != null) {
          try {
            new Promise((resolve) => {
              // eslint-disable-next-line
              NvxAndroid.shareUrl(title, text, this.$options.filters.fullPublicUrl(item.uuid))
              resolve()
            });
          } catch (err) {
            console.log(err)
          }

        }
      }
      else {
        
        await navigator.share({
          title: title,
          text: text,
          url: this.$options.filters.fullPublicUrl(item.uuid)
        }).then(() => {
          console.log('Thanks');
        })
        .catch(console.error);
      }
    },
    copy(token) {
      this.alertPop = true
      this.isCopy = true
      navigator.clipboard.writeText(this.$options.filters.fullPublicUrl(token))
      setTimeout(() => {
        this.alertPop = false
        this.isCopy = false
      }, 1000)
    },
    deleteLink(link) {
      deletePublicLink(link)
      .then((re) => {
        let index = this.links.indexOf(link);
        if (index >= 0) {
          this.links.splice(index, 1)
          location.reload()
        }
        this.showDelete = false;
      })
      .catch((error) => {
        this.showDelete = false;
        let index = this.links.indexOf(link);
        if (index >= 0) {
          this.links.splice(index, 1)
        }
      })
    },
    createLink() {
      // FIXME: limit creation of non folder link. When doing that limit
      // if (this.links.length < 5) {
        if (this.newPubLink['start_date'] == null) {
          this.newPubLink['start_date'] = this.device.tracker_status.last_event
        }
        addPublicLink(this.device.id,this.newPubLink)
        .then((re) => {
          let new_pub_link = re.data;
          this.links.push(new_pub_link);
          this.newPubLink = {type: 'history', duration: 168, start_date: this.lastEvent}
        })
        .catch(console.error)
      // } else {
      //    this.alertPop = true
      //    setTimeout(() => {
      //      this.alertPop = false
      //    }, 1000)
      // }
    }
  },
  
  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }

    this.userOS = getAppOs(navigator, window)

    await this.removeDevices();
    await this.removeSelectedDevice();
    await this.fetchDevices();
    if (parameters["serial"]) {
      await this.setSelectedDevice(this.getDevices.filter((device) => device.serial == parameters["serial"])[0]);
    }
    if (this.getSelectedDevice === null && this.getDevices.length != 0) {
      await this.setSelectedDevice(this.getDevices[0]);
    }
    this.device = this.getSelectedDevice
    this.lastEvent = this.device.tracker_status.last_event;
    await getAllPulicLinks(this.device.id)
    .then((re) => {
      this.links = re.data
      this.loaded = true
    })
    .catch(function (error) {
      console.log(error);
    });
    this.newPubLink['start_date'] = this.lastEvent
    this.startOptions.push(this.day,this.week,this.month,this.lastEvent, this.device.tracker_status.begin_date)
    console.log(this.links)
  },
  updated() {
    feather.replace()
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";

.copied {
  background-color: #000 !important;
}

.add-link {
  background-color: $color-main;
  border: none;
  outline: none;
  border-radius: 0.5em;
  padding: .5em;
  display: flex;
  color: white;
  width: fit-content;
}

.add-panel {
  padding: 1em;
  background-color: #FFF;
  border-radius: 8px;
  
  .link-options {
    display: flex;
    flex-direction: column;
    gap: 1em;
    
    .select-label {
      display: block;
    }
  }
  .btn-panel {
    display: flex;
    justify-content: space-between;
    
    .add-btn {
      border: none;
      outline: none;
      padding: 0.5em 2em;
      border-radius: 0.5em;
      background: #3579F6;
      color: white;
      font-weight: 700;
      width: fit-content;
      align-self: center;
    }
    
    .cancel {
      background-color: #EFEFEF;
      color: #000;
    }
  }
}

.link-container {
  padding: 1em;
  background-color: #FFF;
  border-radius: 8px;
  border: 1px solid #EAEAEAEA;
}

.button-container {
  display: flex;
  gap: .5em;
}

.label-container {
  padding-bottom: .5em;
  color: $color-main;
}

.add-btn {
  border: none;
  outline: none;
  padding: 0.5em 2em;
  border-radius: 0.5em;
  background: #3579F6;
  color: white;
  font-weight: 700;
  width: fit-content;
  align-self: center;
}

.empty-list {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  img {
    width: 200px;
  }
}


// .all-links {
//   display: flex;
//   flex-direction: column;
//   gap: 0.5em;
// }

.delete-pop, .alert-pop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100Vh;
  background-color: #00000059;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .6s ease;
}

.copied-pop, .alert {
  padding: 2em;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  background-color: #F1F8FC;
  border-radius: .5em;
  svg {
    color: $color-main;
    width: 2em;
    height: 2em;
  }
}

.link-label {
  padding: .5em;
  border-radius: .5em;
  width: 100%;
  border: 1px solid #e8e8e8;
  text-transform: capitalize;
  outline: none;
  &::placeholder{
    color: #ADADAD;
  }
}
</style>